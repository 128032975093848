import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import ActionRequestEntity from "../entity";
import Swal from 'sweetalert2';

export default class ActionRequestList extends DefaultController {
    //@ts-ignore
    private datatables: any[];
    private editModal: any;
    private mode: any;

    async init() {
        this.entity = "action_requests";
        this.datatables = [];
        this.editModal = new bootstrap.Modal((document.querySelector("#editActionRequest") as HTMLElement));
        this.mode = (document.querySelector(".card[data-mode]") as HTMLElement).getAttribute("data-mode");

        (document.querySelectorAll(".tab-pane[data-location-id]") as NodeListOf<HTMLElement>).forEach((elem) => {
            this.createTable(elem);
        });
        await super.init();
    }

    getEntityData(elem: any) {
        const location_id = (document.querySelector(".tab-pane.active.show") as HTMLElement).getAttribute("data-location-id")
        return {...ActionRequestEntity.getEntityData(elem), location_id: location_id}
    }

    bindListeners() {
        jQuery(".tab-content").delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Sind Sie sicher?',
                text: "Das Löschen kann nicht rückgängig gemacht werden!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ja, löschen',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('action_request.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('action_request.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatables.forEach((table: any) => {
                    table.ajax.reload();
                })
            }
        });


        jQuery(".tab-content").delegate(".duplicate-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.duplicate(id, this.entity)
            if (requestData.status === 200) {
                this.datatables.forEach((table: any) => {
                    table.ajax.reload();
                })
            }

        });


        jQuery(".tab-content").delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editActionRequestForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                const $newOption = jQuery("<option selected='selected'></option>").val(data.card.uuid).text(data.card.name)
                jQuery("#editActionRequestForm #action_request_card_id").append($newOption).trigger('change');
                jQuery("#editActionRequestForm #action_request_plan_from").val(this.transformDateToInput(data.plan_from));
                jQuery("#editActionRequestForm #action_request_plan_to").val(this.transformDateToInput(data.plan_to));
                this.editModal.show();
            }

        });
        jQuery(".tab-content").delegate(".mode-change-record", "click", async (e: any) => {
            const newMode = e.currentTarget.getAttribute("data-new-mode");
            const id = e.currentTarget.getAttribute("data-id");
            const r = await Utils.entity.upsert({uuid: id, status: newMode}, this.entity)
            this.datatables.forEach((table: any) => {
                table.ajax.reload();
            })

        });
        (document.querySelector("#addNewActionRequestForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewActionRequestForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddActionRequest") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    elem.reset();
                    this.toastr.success(`${Utils.translate('action_request.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editActionRequestForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editActionRequestForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('action_request.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    onSelectGetFilterOptions(elem: any) {
        const location_id = (document.querySelector(".tab-pane.active.show") as HTMLElement).getAttribute("data-location-id")

        if (elem.getAttribute("data-entity") === "cards") {
            return {
                location_id: location_id
            }
        } else {
            return {
            }
        }
    }

    createTable(elem: any) {
        const locationId = elem.getAttribute("data-location-id")
        const table = elem.querySelector("table")
        let buttons = [
            {
                text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                className: 'dt-button add-new btn btn-primary m-2',
                attr: {
                    'data-bs-toggle': 'offcanvas',
                    'data-bs-target': '#offcanvasAddActionRequest'
                }
            }
        ];
        let columns = [
            {data: 'uuid'},
            {data: 'uuid'},
            {data: 'uuid'},
            {data: 'uuid'},
            {data: 'reasoning'},
            {data: 'uuid'},
            {data: 'uuid'},
            {data: 'status'},
            {data: 'uuid'}
        ];
        let columnDefs = [
            {
                targets: 0,
                render: (data: any, type: any, full: any, meta: any) => {
                    return full.card ? full.card.name : "---";
                },
            },
            {
                targets: 1,
                render: (data: any, type: any, full: any, meta: any) => {
                    return full.card ? full.card.access_room : "---";
                },
            },
            {
                targets: 2,
                render: (data: any, type: any, full: any, meta: any) => {
                    let str = "";
                    if (full.ticket_number && full.sam_number) {
                        str = `${full.ticket_number}/${full.sam_number}`
                    } else if(full.ticket_number) {
                        str = full.ticket_number
                    } else if(full.sam_number) {
                        str = full.sam_number
                    }
                    return str;
                },
            },
            {
                targets: 3,
                render: (data: any, type: any, full: any, meta: any) => {
                    let str = "";
                    if (full.visitor_company && full.visitor_name) {
                        str = `${full.visitor_company}/${full.visitor_name}`
                    } else if(full.visitor_company) {
                        str = full.visitor_company
                    } else if(full.visitor_name) {
                        str = full.visitor_name
                    }
                    return str;
                },
            },
            {
                targets: 5,
                render: (data: any, type: any, full: any, meta: any) => {
                    let str = "";
                    if (full.serial && full.grid) {
                        str = `${full.grid}/${full.serial}`
                    } else if(full.serial) {
                        str = full.serial
                    } else if(full.grid) {
                        str = full.grid
                    }
                    return str;
                },
            },
            {
                targets: 6,
                render: (data: any, type: any, full: any, meta: any) => {
                    let dateField = new Date(Date.parse(full.from));
                    if (full.status === "PLANNED") {
                        dateField = new Date(Date.parse(full.plan_from))
                    }
                    return `<span style="display:none">${dateField.getTime()}</span>${dateField.toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"})}`;
                },
            },
            {
                targets: 7,
                render: (data: any, type: any, full: any, meta: any) => {
                    let dateField = new Date(Date.parse(full.to));
                    if (full.status === "PLANNED" || full.status === "IN") {
                        dateField = new Date(Date.parse(full.plan_to))
                    }
                    return `<span style="display:none">${dateField.getTime()}</span>${dateField.toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"})}`;
                },
            },
            {
                targets: 8,
                render: (data: any, type: any, full: any, meta: any) => {
                    return this.transformStatus(full.status);
                },
            },
            {
                targets: 9,
                searchable: false,
                orderable: false,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (this.mode === "history") {
                        return "";
                    }
                    let modechange = "";
                    if (full.status === "PLANNED") {
                        modechange = `<a href="#" data-id="${full.uuid}" data-new-mode="IN" class="text-body mode-change-record" title="Einchecken">
                                    <i class="ti ti-door-enter ti-sm me-2"></i>
                                  </a>`
                    }else if(full.status === "IN") {
                        modechange = `<a href="#" data-id="${full.uuid}" data-new-mode="DONE" class="text-body mode-change-record" title="Auschecken & Abschließen">
                                    <i class="ti ti-door-exit ti-sm me-2"></i>
                                  </a>`
                    }
                    return `<div class="d-flex align-items-center justify-content-end">
                                  ${modechange}
                                  <a href="#" data-id="${full.uuid}" class="text-body duplicate-record">
                                    <i class="ti ti-copy ti-sm me-2"></i>
                                  </a>
                                  <a href="/api/v1/action_requests/${full.uuid}/download" data-id="${full.uuid}" class="text-body">
                                    <i class="ti ti-file-export ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${full.uuid}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${full.uuid}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                },
            }
        ];
        if (this.mode === "history") {
            buttons = [
                {
                    // @ts-ignore
                    extend: 'csv',
                    charset: 'utf-8',
                    text: 'Export',
                    className: 'dt-button add-new btn btn-primary m-2',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7],
                    }
                }]
            columns = [
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'reasoning'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ]
            columnDefs = [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.card ? full.card.name : "---";
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.card ? full.card.access_room : "---";
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str = "";
                        if (full.ticket_number && full.sam_number) {
                            str = `${full.ticket_number}/${full.sam_number}`
                        } else if(full.ticket_number) {
                            str = full.ticket_number
                        } else if(full.sam_number) {
                            str = full.sam_number
                        }
                        return str;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str = "";
                        if (full.visitor_company && full.visitor_name) {
                            str = `${full.visitor_company}/${full.visitor_name}`
                        } else if(full.visitor_company) {
                            str = full.visitor_company
                        } else if(full.visitor_name) {
                            str = full.visitor_name
                        }
                        return str;
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str = "";
                        if (full.serial && full.grid) {
                            str = `${full.grid}/${full.serial}`
                        } else if(full.serial) {
                            str = full.serial
                        } else if(full.grid) {
                            str = full.grid
                        }
                        return str;
                    },
                },
                {
                    targets: 6,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let dateField = new Date(Date.parse(full.from));
                        if (full.status === "PLANNED") {
                            dateField = new Date(Date.parse(full.plan_from))
                        }
                        return `<span style="display:none">${dateField.getTime()}</span>${dateField.toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"})}`;
                    },
                },
                {
                    targets: 7,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let dateField = new Date(Date.parse(full.to));
                        if (full.status === "PLANNED" || full.status === "IN") {
                            dateField = new Date(Date.parse(full.plan_to))
                        }
                        return `<span style="display:none">${dateField.getTime()}</span>${dateField.toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"})}`;
                    },
                },
            ]
        }
        const datatable = jQuery(table).DataTable({
            initComplete: () => {
                jQuery(elem).find(".loading-body").addClass("d-none")
                jQuery(elem).find(".table-responsive").removeClass("d-none")
            },
            ajax: `/api/v1/action_requests?location_id=${locationId}&mode=${this.mode}`,
            columns: columns,
            columnDefs: columnDefs,
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: buttons
        });
        this.datatables.push(datatable);
    }

    transformStatus(status: string) {
        if (status === "PLANNED") {
            return `<span class="badge text-bg-success">${Utils.translate("action_request.status.planned")}</span>`
        }else if (status === "IN") {
            return `<span class="badge text-bg-warning">${Utils.translate("action_request.status.in")}</span>`
        }else if (status === "DONE") {
            return `<span class="badge text-bg-primary">${Utils.translate("action_request.status.done")}</span>`
        }
    }

    transformDateToInput(date: string) {
        const d = new Date(Date.parse(date))
        return (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
    }
}